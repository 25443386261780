import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { ProgressOverviewV6 } from "../types";

const getProgressOverviewV6 = (
  accessToken: string,
  signal: AbortSignal,
  clinicianEmail?: string,
  estimator?: string
) =>
  api
    .get<ProgressOverviewV6>("/v7/clinicians/dashboard/progress", {
      signal,
      headers: {
        Authorization: accessToken,
      },
      params: { email: clinicianEmail, estimator },
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export default function useProgressOverviewV6(
  options: UseQueryOptions<ProgressOverviewV6, Error>,
  clinicianEmail?: string,
  estimator?: string
): UseQueryResult<ProgressOverviewV6, Error> {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).progressOverviewV6(clinicianEmail, estimator),
    ({ signal }) =>
      getProgressOverviewV6(accessToken, signal, clinicianEmail, estimator),
    {
      enabled: !!accessToken,

      staleTime: clinicianEmail ? 0 : Infinity,
      ...(options ?? {}),
    }
  );
}
