import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryOptions } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

export interface MembersPlanOfSuccessionResponse {
  clinician_email: string;
  members: {
    special_conditions: string[];
    user_id: number;
    first_name: string;
    last_name: string;
    preferred_name: string;
    is_linked_account: 1 | 0;
    is_root_account: 1 | 0;
    email: string;
    most_recent_appt: string;
    status: string;
    transfer_note?: string | null;
  }[];
}

const useMembersPlanOfSuccession = <T = MembersPlanOfSuccessionResponse>(
  clinicianEmail: string,
  options?: UseQueryOptions<MembersPlanOfSuccessionResponse, Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).membersPlanOfSuccession(clinicianEmail),
    ({ signal }) =>
      api
        .get<MembersPlanOfSuccessionResponse>(
          "/v2/clinician/members/plan_of_succession_members",
          {
            params: {
              clinician_email: clinicianEmail,
            },
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!clinicianEmail,
      ...(options ?? {}),
    }
  );
};

export default useMembersPlanOfSuccession;
